<script>
export default defineNuxtComponent({
  name: 'TemplateInput',
  props: {
    option: {
      type: Object,
      required: false,
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: [
    'closeInput',
    'updateOption',
    'addOptionsInEditState',
    'removeOptionsInEditState',
  ],
  data() {
    return {
      inputToggle: false,
    };
  },
  methods: {
    toggleInputField() {
      this.inputToggle = !this.inputToggle;
      this.$emit(
        this.inputToggle ? 'addOptionsInEditState' : 'removeOptionsInEditState',
        this.option.value,
      );
    },
    updateOption(index) {
      this.option.label
        = this.option.label.trim() === ''
          ? `Option ${index + 1}`
          : this.option.label.trim();

      this.$emit('updateOption', index);
      this.toggleInputField();
    },
    closeInput(index) {
      this.$emit('closeInput', index);
      this.toggleInputField();
    },
  },
});
</script>

<template>
  <div v-if="!inputToggle" class="w-full font-medium" @click="toggleInputField">
    {{ option.label }}
  </div>
  <div v-else class="flex h-10 w-full flex-row items-center space-x-3">
    <trailblazer-textfield v-model="option.label" expand />
    <trailblazer-button @click="updateOption(index)">
      <trailblazer-icon
        color="var(--tb-color-success)"
        name="checkmark"
      />
    </trailblazer-button>
    <trailblazer-button @click="closeInput(index)">
      <trailblazer-icon
        color="var(--tb-color-error)"
        name="remove"
      />
    </trailblazer-button>
  </div>
</template>
